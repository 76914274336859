import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyDcJUtULVPO1X5SYFVfwFQg4fqvQ2DKwWM",
  authDomain: "satta-bazar-sb.firebaseapp.com",
  projectId: "satta-bazar-sb",
  storageBucket: "satta-bazar-sb.appspot.com",
  messagingSenderId: "671371561146",
  appId: "1:671371561146:web:e6aad121f7db4d9bfc9a83",
  measurementId: "G-9P7W7BWS5G"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };